<template>
  <NuxtLink
    :to="blok?.url?.url ? blok.url.url : `/${locale}`"
    class="[flex:0_0_auto] m-0 p-0 overflow-hidden w-full snap-center"
  >
    <CustomPicture
      class="w-full h-full object-cover lg:h-auto"
      :mobileImage="OptimizeStoryBlokImage(blok.mobileImage?.filename)"
      :desktopImage="OptimizeStoryBlokImage(blok.desktopImage?.filename)"
      :preload="index == 0"
      :loading="index > 0 ? 'lazy' : 'eager'"
      :autoHeight="true"
    />
  </NuxtLink>
</template>

<script setup>
import { OptimizeStoryBlokImage } from '@/utils/helpers'
defineProps({
  blok: Object,
  index: Number,
})

const { locale } = useI18n()
</script>
